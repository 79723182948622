import Wallet from '@/shared/assets/svg/polkadot-wallet.svg';
import { Button } from '@/shared/components';
import ConnectPolkadotWalletModal from '@/shared/components/ConnectPolkadotWalletModal';
import useBoolean from '@/shared/hooks/useBoolean';
import useTracking from '@/shared/hooks/useTracking';
import { EyeIcon } from '@/shared/icons/small';
import { useStateChainAccount } from '../../../hooks/useStateChainAccount';
import { LiquidityProvisionEvents, type LiquidityProvisionTrackEvents } from '../../../types/track';
import ViewOnlyAccountModal from '../../ViewOnlyAccountModal';

export const SignIn = ({ onCompleted }: { onCompleted?: () => void }): JSX.Element => {
  const track = useTracking<LiquidityProvisionTrackEvents>();
  const { value: open, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const { connect } = useStateChainAccount();
  const {
    value: accountIdModalOpen,
    setFalse: closeAccoutIdModal,
    setTrue: openAccountIdModal,
  } = useBoolean(false);

  const connectWallet = () => {
    track(LiquidityProvisionEvents.CtaSignIn, {
      props: { path: window.location.pathname },
    });
    openModal();
    connect();
  };

  return (
    <div className="flex w-[251px] flex-col items-center space-y-5">
      <div className="cf-gray-gradient flex justify-center font-aeonikMedium text-[32px]">
        Sign in
      </div>
      <div className="flex justify-center text-center text-14 leading-none text-cf-light-2">
        You need a funded State Chain account to provide liquidity
      </div>
      <Wallet />
      <Button onClick={connectWallet}>Connect Wallet</Button>

      <button
        onClick={openAccountIdModal}
        type="button"
        className="group flex items-center gap-x-0.5 text-cf-light-1"
      >
        <EyeIcon className="transition duration-300 group-hover:text-cf-green-1" />
        <span className="text-12 transition duration-300 group-hover:text-cf-white">
          Or go to view-only mode
        </span>
      </button>
      <ViewOnlyAccountModal open={accountIdModalOpen} onClose={closeAccoutIdModal} />
      <ConnectPolkadotWalletModal
        accountType="liquidity_provider"
        open={open}
        onClose={closeModal}
        onCompleted={(selectedAccount) => {
          track(LiquidityProvisionEvents.SignInSuccess, {
            props: {
              path: window.location.pathname,
              walletProvider: selectedAccount.meta.source,
              scAccount: selectedAccount.idSs58,
              scAccountName: selectedAccount.meta.name ?? '-',
            },
          });
          onCompleted?.();
          closeModal();
        }}
      />
    </div>
  );
};
